import logo from './assets/logo.svg';
import './App.scss';

import Flickity from 'react-flickity-component';
import { useRef, useState } from 'react';
import { useEffect } from 'react';

import useWindowDimensions from './hooks/useWindowDimensions';

import { useAllPrismicDocumentsByType } from '@prismicio/react';

import grisinn from './assets/grisinn.svg';
import grisinnBros from './assets/grisinn-bros.svg';
import Analytics from './analytics';

const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');

Analytics.initGA('G-V2RLP7YDL4');
Analytics.initPixel('796273500718168');

const App = () => {
    const [pages] = useAllPrismicDocumentsByType('bonus_rettur');
    const products = pages?.map((page) => page.data);
    const { height, width } = useWindowDimensions();

    const flickityRef = useRef();

    const flickityOptions = {
        initialIndex: 3,
        freeScroll: true,
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
    };

    const [spinning, setSpinning] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (spinning && flickityRef.current) {
            setSelectedItem(null);
            let timesLeft = Math.ceil(Math.random() * 30) + 20 + Math.floor(Math.random() * products.length);
            const inter = setInterval(function () {
                if (timesLeft) {
                    flickityRef.current.next();
                    timesLeft--;
                } else {
                    setSelectedItem(products[flickityRef.current.selectedIndex]);
                    setSpinning(false);
                    clearInterval(inter);
                    Analytics.trackSpin(products[flickityRef.current.selectedIndex]?.name[0]?.text);
                }
            }, 50);
        }
    }, [spinning]);

    useEffect(() => {
        Analytics.logPageView();
    }, []);

    if (!pages) {
        return <p>loading</p>;
    }

    return (
        <div>
            {/* Results */}
            {selectedItem && (
                <div className="selected-backdrop">
                    <div className="selected-container" style={{ height }}>
                        <div className="titles">
                            <h1>Vúhú!</h1>
                            <p>Það er {selectedItem.name[0].text} í kvöld!</p>
                        </div>
                        <div className="result-image">
                            <img src={selectedItem.image.url} alt="" />
                        </div>
                        <div className="results">
                            {/* <h1>{formatISK(selectedItem.price)} kr.</h1> */}
                            <p>{selectedItem.name[0].text}</p>
                        </div>
                        <div className="grisinn">
                            <img src={grisinnBros} alt="" />
                        </div>
                        <div className="footer" onClick={() => !spinning && setSpinning(true)}>
                            <p>Ekki nógu gott?</p>
                            <b>Snúa aftur</b>
                        </div>
                    </div>
                </div>
            )}

            {/* Spinner */}
            <div className="container" style={{ height }}>
                <header className="header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <div className="titles">
                    <p>Hvað er í matinn í kvöld?</p>
                    <h1>Prófaðu hjólið!</h1>
                </div>
                <div className="spinner-container">
                    <Flickity
                        className={'spinner'} // default ''
                        elementType={'div'} // default 'div'
                        options={flickityOptions} // takes flickity options {}
                        disableImagesLoaded={false} // default false
                        reloadOnUpdate // default false
                        static // default false
                        pageDots={false}
                        freeScroll={true}
                        contain={true}
                        prevNextButtons={false}
                        // pageDots={false}
                        flickityRef={(c) => (flickityRef.current = c)}
                    >
                        {products.map((product, i) => (
                            <img key={i} src={product.image.thumb.url} alt="" id={`item-${i}`} />
                        ))}
                    </Flickity>
                    {/* <div className="spinner-view"></div> */}
                </div>
                {/* <div className="settings"></div> */}
                <div className="grisinn">
                    <img src={grisinn} alt="" />
                </div>
                <div className="footer" onClick={() => !spinning && setSpinning(true)}>
                    <b>Snúa hjólinu</b>
                </div>
            </div>
        </div>
    );
};

export default App;
