import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const initGA = (id) => {
    // console.log('GA init')
    ReactGA.initialize(id);
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    ReactGA.plugin.require('ec');
    ReactPixel.pageView();
};

const initPixel = (id) => {
    // console.log('Pixel init')
    ReactPixel.init(id);
    ReactPixel.pageView();
};

const logPageView = () => {
    // console.log(`Logging pageview for ${window.location.pathname + window.location.search}`)
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
};

const trackSpin = (spin) => {
    ReactPixel.track('Lead');
    ReactGA.event({ category: 'Spinner', action: 'Spin' });
    // window.dataLayer && dataLayer.push({ event: 'lead' });
};

export default {
    initGA,
    initPixel,
    logPageView,
    trackSpin
};
